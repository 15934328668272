
import LinkResolver from '~/components/LinkResolver.vue'
export default {
  name: 'HeroBlockQuote',
  components: {
    LinkResolver,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
